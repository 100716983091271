
export const portfolioData = [
  {
    title: 'Realtime Data AI Voice Assistant (OpenAI, APIs, React, Node.js)',
    images: [],
    videoUrls: [
      'https://www.youtube.com/embed/1MzdpzK6kg4'
    ],
    link: 'https://github.com/LeifChristian/GPT-speech-realtime-data',
    description: 'Voice-enabled AI assistant using OpenAI API with real-time data retrieval, speech-to-text, and TTS capabilities. Features multiple conversation threads, image generation and analysis.'
  },
  {
    title: 'Hustle Fundraising (MongoDB, Express, React, Node.js, Tailwind)',
    images: [],
    videoUrls: [
      'https://www.youtube.com/embed/V-ySIouF6fQ'
    ],
    link: 'https://hustlefundraise.com',
    description: 'MERN stack fundraising app for fraternities and sororities'
  },
  {
    title: 'MyPlaces Location App (React Native)',
    images: [],
    videoUrls: [
      'https://www.youtube.com/embed/9Q0JQhoIebA'
    ],
    link: 'https://github.com/LeifChristian/myPlaces-EAS-Expo',
    description: 'React Native Maps applicaton for saving places. Google search for places and easy save pins, directions, one handed navigation. Very useful for travel!'
  },
  {
    title: 'File Explorer (Vue.js, Electron)',
    images: [],
    videoUrls: [
      'https://www.youtube.com/embed/2cH0N8azup4'
    ],
    link: 'https://github.com/LeifChristian/electron-vue-GUI-file-explorer',
    description: 'Explore the file system, see all available drives and search for files. Files open with their respective programs. Works for Mac OS, windows and Linux (with X-server installed)'
  },
];